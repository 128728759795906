$font-family-barlow: "Barlow";
$bluePrimary: #345DAE;
$blackPrimary: #202842;
$darkBluePrimary: #0B2240;
$yellowPrimary: #FAAF40;
$blueSecondary: #2D4DC8;
$skyBlueSecondary: #869FFD;
$lightGraySecondary: #DDDDDD;
$graySecondary: #DCDFF1;
$grayPrimary: gray;
$white: #ffffff;
$tabPanel: #f2f2f2;