@import "styles/styleVariables.scss";

.burger-menu-paper {
  background-color: white;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);


  .MuiSvgIcon-root {
    font-size: 1.4rem;
  }

  svg {
    margin-left: 5px;
    margin-right: 5px;
  }

  li:hover {
    background-color: $skyBlueSecondary;
    color: white
  }
}