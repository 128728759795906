@import "styles/styleVariables.scss";

.MuiDataGrid-cell {
  align-content: center;
}

.MuiDataGrid-toolbarContainer {
  padding-right: 1rem;
  padding-left: 0.75rem;
  width: 99%;

  [aria-label="Export"],
  [aria-label="Show filters"] {
    color: $bluePrimary;
    border: 1px solid $bluePrimary;
    border-radius: 6px;
    padding: 0.3rem 0.8rem;
    text-transform: none;
  }

  button {
    font-size: 14px;
    color: $darkBluePrimary;
  }

  .MuiSvgIcon-root {
    font-size: 1.5rem;
  }
}

.MuiCheckbox-root {
  svg {
    width: 1.5rem
  }
}

.basic-data-grid {
  .MuiDataGrid-root {
    border: none;
  }

  .MuiDataGrid-root .MuiDataGrid-container-top[role="row"],
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-columnHeaderRow {
    background: #C4D1EB !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
    font-size: 14px;
  }

  .MuiDataGrid-columnHeaderTitleContainerContent {
    font-family: $font-family-barlow;
    font-size: 14px;
    color: $darkBluePrimary;
  }

  .MuiDataGrid-row {
    // background: rgba(242, 242, 242, 1);
    background-color: white;
    color: $darkBluePrimary;
    font-size: 14px;
    cursor: pointer;
  }

  .MuiDataGrid-row:hover {
    background: rgba(221, 221, 221, 1);
  }

  .MuiDataGrid-main {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 9px;
    // max-height: 25rem;
    // width: 98%;
    overflow: none;
  }

  .MuiDataGrid-footerContainer {
    border: none;
    justify-content: center;

    .MuiTablePagination-select {
      margin-top: 0;
    }

    .MuiTablePagination-selectIcon {
      margin-top: 0;
    }

    p {
      margin: 0;
      font-size: 1rem;
    }

    button {
      width: 3rem;
    }
  }
}


// Custom Data Grid expandable rows Stylings

.cstm-datagrid-table {
  background-color: white;
  --bs-table-bg: inherit;
}

.cstm-col-header {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 700;
  font-size: 14px;
  color: #0B2240;
  border: none;
}

.cstm-datagrid-table-header {
  background: #C4D1EB;

}

// .cstm-datagrid-table-row {}

.cstm-datagrid-data-col {
  border: none;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  color: #0B2240;

  p {
    font-size: 14px;
    color: #0B2240;
  }
}