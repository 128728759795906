/* Chat Container */
.chat-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

/* Chat Body */
.chat-body {
  display: flex;
  overflow-y: auto;
  padding: 16px;
  margin-bottom: 80px;
  flex-direction: column;
  gap: 16px;
  background-color: #f4f4f4;
}

.chat-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chat-message-user {
  align-items: flex-end;
}

.chat-bubble {
  padding: 12px 16px;
  max-width: 70%;
  border-radius: 12px;
  background-color: #e3f2fd;
  position: relative;
  display: inline-block;
}

.chat-message-bot .chat-bubble {
  background-color: #f5f5f5;
}

/* Timestamp */
.chat-message-timestamp {
  display: block;
  font-size: 12px;
  color: #999;
  margin-top: 4px;
  text-align: right;
}

.chat-message-text {
  word-wrap: break-word;
  font-size: 14px;
}

/* Input Section */
.chat-input {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 16px;
  border-top: 1px solid #e0e0e0;
  background-color: #ffffff;
  gap: 8px;
}

.chat-input-field .MuiOutlinedInput-root {
  border-radius: 50px;
  padding-right: 30px;
}

.chat-send-icon {
  position: absolute;
  right: 1;
  color: #1976d2;
}

.chat-voice-button {
  padding: 8px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-voice-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
