.businessProfile {
  width: 90%;
  margin: auto;
}

#menu-serviceAreas {

  .Mui-selected {
    background: gray
  }
}

//My Profile Page Styles

.my-profile-container {
  .my-profile-menu {
    border-radius: 6px;
    cursor: pointer;
  }

  .my-profile-menu-icon {
    font-size: 1.4rem;
  }

  .my-profile-menu:hover {
    background: "lightgray";
    border-radius: 6px
  }

  .myProf-active-menu {
    background: #aac1ed;
  }

  .myProf-inactive-menu {}
}