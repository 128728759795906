@import "styles/styleVariables.scss";

.Mui-selected {
  color: $darkBluePrimary !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.MuiTabPanel-root {
  padding: 0;
}

.tab-button {
  text-transform: none;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Barlow";
  color: $darkBluePrimary;
  // border: 1px solid #f7f4f4;
  // border-radius: 8px;
  height: 6px;
  // background-color: #f7f4f4;
  margin: 0 5px;


}