@import "styles/styleVariables.scss";

.add-project-form {
  border: 1px solid lightgray;
}

.stepContent {
  background-color: transparent;
  margin: 0;
  padding: 0
}

.registration-formField {

  textarea,
  input {
    color: black;
    font-weight: 500;
    background-color: white;
  }

  input,
  .MuiSelect-select {
    border-radius: 0.75rem;
    height: 1rem;
    min-height: 0.55rem;
    font-weight: 500;
    color: black;
  }

  .MuiSelect-multiple {
    height: auto;
    min-height: 0.55rem;
  }
}

.property-details-textarea {
  .MuiOutlinedInput-root {
    min-height: 7rem;
  }

}

.folder-icon {
  height: 175px;
  margin: 1rem;
  width: 218px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 700px) {
    width: 183px;
    margin: 0.5rem
  }
}

.folder-icon:hover {
  transition: transform 0.3s ease;
  transform: scale(1.05);
}

.fol-content {
  padding: 0.5rem;
}

.fol-footer {
  margin-top: auto;
  margin-bottom: 1rem;
  height: 1rem;
  right: 0;
  position: relative;
  align-self: end;

  .MuiFormGroup-root {
    height: 0;
  }

  .MuiFormControlLabel-root {
    height: 0;
  }
}


.col-6 {
  width: 47%;
}

.commercial {
  background-image: url("../../assets/properties/folder_bg_commercial.png");
}

.residential {
  background-image: url("../../assets/properties/folder_bg_residential.png");
}

.properties-list-container {
  background-color: #f2f2f2;
}

.property-name-fol {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.MuiAccordionSummary-root {
  border-bottom: 1px solid;
}

.filter-checkbox {
  height: 1rem;
  text-align: center;
  align-self: center;
}

.footer-actions-button {
  button {
    bottom: 0.8rem;
    background-color: #f2f2f2;
    margin-right: 0.5rem;

    svg {
      height: 1rem;
      width: 1rem;
    }
  }
}

.site-location-bg {
  background-image: url("../../assets/app/map-background.png"); // Replace with your map image
  background-size: cover;
  background-position: center;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 1rem;
  position: relative;
  margin: 1rem 1rem 0 1rem
}

.MuiTabPanel-root {
  background-color: $tabPanel;
  padding: 0
}