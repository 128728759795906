@import "styleVariables.scss";

body {
  font-size: 1rem;
  line-height: 16px;
  font-family: $font-family-barlow;
  height: 100%;
  width: 100%;
}

.app {
  height: 100%;
  width: 100%;
  overflow: hidden;
  // text-transform: capitalize !important;

  .app-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
}

.section {
  width: 85%;
  display: flex;
  place-content: center;
  margin: 2rem auto;

  .row {
    margin: 2rem 0;
  }
}

.h1,
.h2,
.subtitle1,
.body1,
.body2 {
  font-family: $font-family-barlow;
}

.h1 {
  font-size: 28px;
  font-weight: 700;
  line-height: 33.6px;
  color: $darkBluePrimary;
}

.h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: $darkBluePrimary;
}

.h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: $darkBluePrimary;
}

.h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: $darkBluePrimary;
}

.h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: $darkBluePrimary;
}

.h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: $darkBluePrimary;
}

.subtitle1 {
  font-size: 1rem;
  font-weight: 600;
  font-family: $font-family-barlow;
}

.body1 {
  font-size: 1rem;
}

.body2 {
  font-size: 0.75rem;
}

.primaryInput {
  width: 100%;

  input {
    color: black;
    background: white;
    border-radius: 0.75rem;
    height: 0.85rem;
    border-color: black;
    font-weight: 500;
  }

  fieldset {
    border: none;
  }
}

.primary-btn,
.primary-button,
.primaryButton {
  cursor: pointer;
  background-color: $bluePrimary;
  height: 2.4rem;
  border-radius: 6px;
  color: white;
  text-transform: none;
  font-family: $font-family-barlow;
  font-weight: 600;
  border: none;
  // width: 7rem;
  width: auto;
  margin-left: 3px;
  margin-right: 3px;

  .MuiButton-root {
    color: white;
  }
}

.outlinedButton,
.secondaryButton {
  color: $bluePrimary;
  border: 1px solid $bluePrimary;
  height: 2.4rem;
  border-radius: 9px;
  text-transform: none;
  font-family: $font-family-barlow;
  font-weight: 600;
  background-color: white;
  width: 7rem;
}

.primaryButton:hover {
  background-color: $blueSecondary;
}

.secondaryButton:hover {
  background-color: $blueSecondary;
  color: white;
}

.disabled,
.not-allowed {
  cursor: not-allowed;

  .MuiSelect-select {
    cursor: not-allowed;
  }

  .MuiInputBase-input {
    cursor: not-allowed;
  }
}

.disabledButton,
.disabledButton:hover {
  background-color: lightgray;
  cursor: not-allowed;
}

.grey-label {
  color: $grayPrimary;
}

.paddingNone {
  padding: 0;
}

.half-width {
  width: 50%;
}

.full-width {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.error-text {
  color: red;
  font-size: 14px;
}

.flexWrapper {
  flex-wrap: wrap;
}

.fixed-header {
  position: fixed;
  top: 0;
  z-index: 1000;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  z-index: 1000;
}

.form-field-wrapper {
  margin-top: 1.75rem;

  p {
    margin-bottom: 0.3rem;
  }
}

.MuiDatePickerToolbar-title {
  font-size: 3rem;
}

.MuiPickersMonth-monthButton {
  font-size: 1rem;
}

.form-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $darkBluePrimary;
}

.registration-formField,
.form-field {
  width: 100%;

  .MuiOutlinedInput-root {
    border-radius: 10px;
    background-color: white;

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #DDDDDD;
    }
  }

  textarea,
  input {
    color: black;
    font-weight: 500;
    background-color: white;
  }

  input,
  .MuiSelect-select {
    border-radius: 0.75rem;
    height: 0.55rem;
    min-height: 0.55rem;
    font-weight: 500;
    color: black;

    p {
      margin-top: -0.5rem;
      font-weight: 500;
      color: black;
    }
  }

  .MuiSelect-multiple {
    height: auto;
    min-height: 0.55rem;
  }

  fieldset {
    border: 1px solid #DDDDDD;
  }

  .MuiFormHelperText-root {
    font-size: 12px;
    margin-left: 0;
  }
}

.google-location-field {
  input {
    padding: 1.2rem;
    border: 1px solid #DDDDDD;
  }
}

.MuiTablePagination-select {
  margin-right: 10px;
  font-size: 16px;
  margin-top: -10px;
}

.MuiTablePagination-selectIcon {
  margin-top: -5px;
}

.divider {
  width: 98%;
  height: 1px;
  gap: 0px;
  opacity: 0px;
  background: rgba(221, 221, 221, 1);
}

.upload-dropZone-text {
  color: $grayPrimary;
  font-size: 16px;
  font-weight: 600;
}

.basic-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;

  th {
    background-color: #f2f2f2;
    font-family: $font-family-barlow;
    font-size: 14px;
    color: $darkBluePrimary;
    font-weight: bold;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
}

.custom-marker-icon {
  width: 25px;
  height: 41px;
  background-color: blue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.icon-btn {
  svg {
    font-size: 14px;
  }
}

.save,
.success,
.edit-btn {
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-btn {
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.main-blue {
  color: #345DAE;
}

.dark-blue {
  color: #0B2240;
}

.autocomplete-field,
.MuiAutocomplete-input {
  height: 1rem;

  input {
    height: 1rem
  }
}

.underline-text {
  font-weight: 700;
  color: #0B2240;
}

.underline-text:hover {
  text-decoration: underline;
}

.overflowText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}