@import "styles/styleVariables.scss";

.sidebar-menu {
  .MuiPaper-root {
    background-color: $darkBluePrimary;
  }
}

.sidebar-menu-item {
  color: white
}

.sidebar-footer {
  background: black;
  color: white;
  width: 85%;
  align-self: center;
  border-radius: 10px;
  padding: 0.75rem;
  margin: 1rem;
}

.sidebar-drawer {
  .MuiSvgIcon-root {
    color: white;
  }
}

.profile-name-text {
  max-width: 5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  align-content: center;
}