@import "styles/styleVariables.scss";

.login-section {
  background-color: $darkBluePrimary;
  overflow: auto;
  height: 100vh;
}

.login-block {
  width: 90%;
  margin: auto;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.login-formField {
  margin-top: 1rem;
  width: 100%;
  align-content: center;
  color: white;
}

.logo {
  width: 14rem;
  display: block;
  margin: auto;
  padding: 0rem 0;
}

.login-text {
  font-weight: 900;
  margin: 1rem;
  text-align: center;
  font-size: 1.3rem;
}


.remember-me-text {
  display: inline-block;
  margin-top: 3px;
}

.login-button-section {
  margin-top: 1rem;
  width: 100%;
  margin-bottom: 1rem;

  .login-button {
    width: 100%;
  }

  .forgot-password {
    cursor: pointer;
    color: #3457DC;
    /* text-decoration: underline; */
    // text-align: center;
    margin-top: 1rem;
  }

  .forgot-password:hover {
    text-decoration: underline;
  }
}

.login-signup-section {
  margin-top: 1rem;

  .signup-now-text {
    color: $yellowPrimary;
    cursor: pointer;
    text-align: center;
  }

  .signup-now-text:hover {
    text-decoration: underline;
  }
}

.signup-now {
  border-top: 1px solid $lightGraySecondary;
  width: 100%;
}

.login-ad-block {
  // background-image: url("../../assets/loginRegistration/login-background-image.png");
  background-position: center;
  background-size: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* Center content horizontally */
}

.login-ad-text {
  width: 80%;
  margin-top: 20%;
}

.header-text {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.know-more {
  width: 7rem;
  background-color: transparent;
}

.know-more:hover {
  color: white;
}

.register-dialogContent {
  padding: 0
}

.registration-type-container {
  min-height: 17rem
}

.select-box-container {
  padding: 1rem;
  margin: auto;
  text-align: center;
  place-content: center
}

.register-type-text {
  text-align: center;
  font-size: 1.3rem;
  height: 20%;
  margin-top: 1rem;
}

.register-type-selectBox {
  max-width: 200px;
  height: 150px;
  place-content: center;
  cursor: pointer;
  border: 2px solid $bluePrimary;
  margin: 2rem;
  background: white;
  border-radius: 0.75rem;

  .select-text {
    color: $darkBluePrimary;
    font-weight: 700;
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }
}

.hoverEffect {
  transition: transform 0.3s ease;
}

.hoverEffect:hover {
  transform: scale(1.05);
}


.register-select-img {
  width: 30%;
  height: auto;
}

.registerForm-container {
  width: 60%;
  margin: auto;

  @media screen and (max-width: 600px) {
    width: 90%;
  }
}


.stepperHeader {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;

  .MuiStepConnector-root {
    top: 22px;

    .MuiStepConnector-root {
      width: 90%;
      margin: auto
    }
  }
}

.formField {
  width: 50%;
  padding: 1rem;
  margin-top: 0.5rem;

  p {
    margin-bottom: 0.3rem;
  }

  @media screen and (max-width: 500px) {
    width: 100% !important;
  }
}

.remember-me-checkbox {
  width: 1rem;

  fieldset {
    border: none
  }
}

.remember-me-checkbox {
  display: inline-block;
}

.remember-me-checkbox .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}

.remember-me-checkbox .Mui-checked {
  color: #007bff;
  /* Color when checked */
}

.social-login-btn {
  margin: 1rem 0;
  margin-left: 0;
  width: 100%
}

.social-btn {
  width: 100%;
  border-radius: 5px;
  padding: 0.75rem;
  background-color: white;
  color: black;

  .social-logo {
    width: 2rem;

    img {
      width: 1.5rem
    }
  }

  .social-text {
    margin-left: 5px;
    font-size: 15px;
  }
}

.social-btn:hover {
  background-color: white;
}

.cl-or-divider {
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  span {
    padding: 0 1.5rem
  }
}

.cl-or-divider::before {
  content: "";
  display: block;
  background: #C6D2D9;
  width: 100%;
  height: 1px;
}

.cl-or-divider::after {
  content: "";
  display: block;
  background: #C6D2D9;
  width: 100%;
  height: 1px;
}

.email-singup {
  padding: 2rem;
}

.singup-email-field {
  input {
    border: 1px solid gray;
  }
}

.singUpButton {
  margin-top: 2rem;
  width: 90%;
  align-items: center;
}

.save-details-button {
  width: 10rem;
}

.stepContent {
  margin: auto;
  background-color: white;
  padding: 1rem;
}

.prev-next-btn {
  width: 10rem;
}

.link-text {
  color: $bluePrimary;
  text-decoration: underline;
}

.link-text:hover {
  cursor: pointer;
}

.terms-conditions-text {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 6rem;
  font-size: 12px;
}

.email-sent-response-section {
  .link-text {
    font-size: 16px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
  }
}

.registration-form {
  background-color: #F2F2F2;
  min-height: calc(100vh - 4rem);
  align-content: center;
  padding-top: 4.5rem;
}

// .form-label {
//   font-weight: 500;
//   color: $darkBluePrimary;
// }

.payment-terms {
  font-weight: 600;
  color: $darkBluePrimary;
  font-size: 0.75rem;

  span {
    margin-left: 0.5rem;
  }
}

div>.confirmation-container {
  align-content: center;
  height: 100%;

  .check-circle {
    font-size: 8rem;
  }
}

.confirmation-message1 {

  color: $bluePrimary;

  h3 p {
    font-weight: 600;
    color: $bluePrimary;
  }
}

.confirmation-message2 {
  p {
    color: $darkBluePrimary;
    font-weight: 500;
  }
}

// .register-form-type-homeowner {
//   .copilot-header-footer {
//     position: fixed;
//     bottom: 0;
//   }
// }

// image Slider Styles

.carousel {
  position: relative;
  width: 100%;
  /* Adjust as needed */
  overflow: hidden;
}

.carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  background-size: cover;
  background-position: center;
}

.carousel-slide.active {
  opacity: 1;
}

.carousel-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.carousel-indicators {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.indicator.active {
  background-color: white;
}


// 