// @import "styles/styleVariables.scss";

.timesheet-container {
  margin: 0 auto;
  padding: 0px;
  font-family: Arial, sans-serif;
}

.timesheet-title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.timesheet-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.timesheet-table th,
.timesheet-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.timesheet-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.timesheet-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.timesheet-button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
}

.delete-button:hover {
  background-color: #ff3333;
}

.add-button {
  background-color: #4CAF50;
  color: white;
  margin-right: 10px;
}

.add-button:hover {
  background-color: #45a049;
}

.save-button {
  background-color: #008CBA;
  color: white;
}

.save-button:hover {
  background-color: #007aa3;
}

.timesheet-actions {
  display: flex;
  justify-content: flex-end;
}

.error-message {
  color: #ff0000;
  margin-top: 10px;
}

.hours-cell {
  position: relative;
  width: 8rem;
}

.popup-trigger {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  padding: 0 5px;
}

.popup {
  // position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
}

.popup input {
  display: block;
  margin-bottom: 10px;
}

.hours-date-input {
  width: 80%;

  input {
    height: 0;
    min-height: 0;
  }

}


// 
// 
// 
.timesheet {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f5f7fa;
  padding: 20px;

  input {
    width: 70px;
    text-align: center;
  }
}

.timesheet-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #e0e0e0;
  padding: 10px;
  text-align: center;
}

thead {
  background-color: #f0f0f0;
}

.select-project {
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
}

.icon {
  margin-right: 5px;
}

.remove {
  background: none;
  border: none;
  color: #e74c3c;
  cursor: pointer;
}

.timesheet-footer {
  margin-top: 20px;
}

.timesheet-footer button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
}