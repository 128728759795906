.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  font-family: Arial, sans-serif;
  // background-color: #f8f9fa;
  color: #343a40;
  text-align: center;
}

.not-found h1 {
  font-size: 8rem;
  margin-bottom: 0;
  color: #dc3545;
}

.not-found h2 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

.not-found p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.home-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.home-link:hover {
  background-color: #0056b3;
}