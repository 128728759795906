.preview-dialog {
  .MuiPaper-root {
    height: "100%";
    width: "100%"
  }
}

.preview-model-container {
  .MuiPaper-root {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  .document-container {
    width: auto;
  }
}