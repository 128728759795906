.PL-card-footer {
  .task-status-div {
    width: auto;
    height: 1.5rem;
    padding: 0.3rem;
  }

  .task-indicator-circle {
    margin-left: 0.15rem;
    margin-right: 0.15rem;
  }
}