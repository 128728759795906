.add-project-form {
  border: 1px solid lightgray;
}

.page-footer {
  height: 5rem;
  min-height: 5rem;
  right: 0;
  margin-right: 2rem;
}

.project-details {
  input {
    height: 6rem;
  }
}

.project-logo-upload-area {
  gap: 0px;
  border-radius: 9px;
  height: 10rem;
  border: 1px;
  // opacity: 0px;
  background: rgba(242, 242, 243, 1);
  border: 1px solid rgba(221, 221, 221, 1)
}

.project-photos-upload-area {
  gap: 0px;
  border-radius: 9px;
  height: 15rem;
  border: 1px;
  // opacity: 0px;
  background: rgba(242, 242, 243, 1);
  border: 1px solid rgba(221, 221, 221, 1)
}

.google-location-field {
  input {
    width: 100%;
  }
}

.project-photo {
  position: relative;
  margin: 5px;
}

.remove-file-btn {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.remove-file-btn:hover {
  background-color: darkred;
}

.update-status-field {
  input {
    height: 2rem
  }

  fieldset {
    height: 2.5rem;
    border: none;
    border-bottom: 1px solid lightgray;
    border-radius: 0;

  }
}

.card-value {
  font-size: 38px;
}

.donut-chart-overlay {
  position: absolute;
  // left: "55%",
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 180px;
  height: 180px;
  background-color: #f2f2f2;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}


// Schedule Page Styles 
.scheduled-jobs-list {
  .cstm-datagrid-table-header {
    background: #345DAE;
    color: white;
  }

  .cstm-col-header {
    color: white
  }
}