@import "styles/styleVariables.scss";

.copilot-header-footer {
  width: 100%;
  background-color: $blackPrimary;
  height: 4.5rem;
  align-content: center;
}



.footer {
  text-align: center;
  color: $yellowPrimary;

}

.header-logo {
  width: 10rem;
  display: block;
  margin: auto;
  padding: 1rem;
}

.containerSection {
  display: flex;
  flex-wrap: wrap;
}

.close-icon {
  svg {
    color: $grayPrimary;
    height: 1.5rem;
  }
}

.header-project-selector {
  width: 15rem;
  left: 4rem;
  position: relative;

  @media screen and (max-width: 400px) {
    width: 12rem;
    left: 0;
  }

  .MuiOutlinedInput-root,
  .MuiAutocomplete-root {
    background-color: white;
  }

  input {
    color: black
  }

  fieldset {
    // border-color: white;
    color: black;
  }

  svg {
    color: black
  }
}