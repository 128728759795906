.task-status-div {
  width: 8rem;
  height: 2.5rem;
  border-radius: 5px;
}

.task-indicator-circle {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.task-status-text {
  color: "#0B2240";
  margin-left: 0.5rem;
}