.status-indicator {
  width: 0.75rem; // 12px
  height: 0.75rem; // 12px
  border-radius: 9999px; // Full rounded

  &--active {
    background-color: #10B981; // Green-500
  }

  &--inactive {
    background-color: #D1D5DB; // Gray-300
  }

  &--warning {
    background-color: #F59E0B; // Yellow-500
  }

  &--error {
    background-color: #EF4444; // Red-500
  }
}

// Usage example:
.status-wrapper {
  display: flex;
  align-items: center;

  .status-text {
    margin-left: 0.5rem;
  }
}