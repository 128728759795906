  
  .wave-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 100px;
    overflow: hidden;
  }
  
  .wave {
    position: absolute;
    width: 300%; /* Double the width to create seamless movement */
    height: 100px;
    fill: none;
    stroke-width: 3;
    stroke: linear-gradient(to right, #007bff, #ffc107); /* Gradient color */
    animation: smoothWave 8s infinite linear; /* Longer duration for smoothness */
  }
  
  .wave1 {
    stroke: #007bff; /* Blue wave */
    opacity: 0.8;
  }
  
  .wave2 {
    stroke: #ffc107; /* Yellow wave */
    opacity: 0.7;
    animation-delay: 3s; /* Offset for a smoother layered effect */
  }
  
  /* Animation for smooth wave motion */
  @keyframes smoothWave {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%); /* Moves by half the width for seamless looping */
    }
  }
  