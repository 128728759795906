@import 'styles/styleVariables.scss';

.markerPopup {
  .leaflet-popup-content {
    width: 20rem !important;

    p {
      margin: 0.85rem 0
    }
  }
}



// New Dashboard CSS Styles
.dashboard-container {
  background-color: #dddddd59;
}

.dashboard-card {
  background-color: #FFFFFF;
  min-height: 4rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.card-flex-grow {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.help-icons {
  font-size: 1rem;
  color: #345DAE;
  width: 23px;
  height: 25px;
}

.card-title {
  font-size: 24px;
  font-weight: 400;
  margin-left: 2rem;
}

.card-value {
  color: #345DAE;
  font-size: 48px;
  font-weight: 400;
  line-height: 72px;
  text-align: left;

  span {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
  }
}

.overview-col-section {
  flex: 1 1 calc(33.333% - 20px);
  /* Subtracting the gap to account for spacing */
  min-width: 250px;
  /* Adjust this value based on your content needs */
  display: flex;
  flex-direction: column;
}


/* If you want all cards to have the same height regardless of content */
.card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

// /* Ensure that any child elements of .card-content also grow to fill space */
.card-content>* {
  flex-grow: 1;
}

// /* Media query for smaller screens */
@media (max-width: 768px) {
  .dashboard-card {
    flex-basis: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .dashboard-card {
    flex-basis: 100%;
  }
}

.alert-sourceName {
  color: red;
  font-weight: 700;
}

.alert-title {
  font-family: Barlow;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #0B2240;
}

.alert-content {
  font-family: Barlow;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}

.alert-timeago {
  font-family: Barlow;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}

.alert-item:hover {
  text-decoration: underline;
}