.chat-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

/* Chat Body */
.chat-body {
  display: flex;
  /* overflow-y: auto; */
  max-width: "500px";
  padding: 16px;
  margin-bottom: 80px;
  flex-direction: column;
  gap: 16px;
  background-color: #f4f4f4;
}

.chat-message {
  display: flex;
  flex-direction: column;
  max-width: "500px";
  align-items: flex-start;
}

.chat-message-user {
  align-items: flex-end;
}

.chat-bubble {
  padding: 12px 16px;
  max-width: 70%;
  border-radius: 12px;
  background-color: #e3f2fd;
  position: relative;
  display: inline-block;
}

.chat-message-bot .chat-bubble {
  background-color: #f5f5f5;
}

/* Timestamp */
.chat-message-timestamp {
  display: block;
  font-size: 12px;
  color: #999;
  margin-top: 4px;
  text-align: right;
}

.chat-message-text {
  word-wrap: break-word;
  font-size: 14px;
}

/* Input Section */
.chat-input {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 16px;
  border-top: 1px solid #e0e0e0;
  background-color: #ffffff;
  gap: 8px;
}

.chat-input.mobile {
  position: absolute;

  bottom: 140px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  background-color: #ffffff;
  padding: 1px;
  gap: 8px;
}

/* Floating Keyboard Button */
.floating-keyboard-button {
  position: fixed;
  bottom: 120px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.keyboard-icon {
  width: 24px;
  height: 24px;
}

.chat-input-field .MuiOutlinedInput-root {
  border-radius: 50px;
  padding-right: 30px;
}

.chat-input-field.mobile {
  border-radius: 50px;
  
  position: fixed;
  bottom: 120px;
  z-index: 1000;
  background-color: #ffffff;
  margin-Left: 1px;
  padding-right: 0px;
  width: 75%;
  padding: '30px';
  display: flex;
  border-top: 1px solid #e0e0e0;
}

.chat-send-icon {
  position: absolute;
  right: 1;
  color: #1976d2;
}

.chat-voice-button {
  padding: 8px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-voice-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chat-typing-indicator {
  position: absolute;
  opacity: 0;
  left: 90%; /* Centrar */
  transform: translateX(-50%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  bottom: 10px;
  font-size: 14px;
  color: gray;

  gap: 8px;
  position: fixed;
  bottom: 100px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.chat-typing-indicator.mobile {
  left: 2%;
  bottom: 180px;
}

.chat-typing-indicator.visible {
  opacity: 1;
  transform: translateY(0);
}

.typing-dot {
  width: 10px;
  height: 10px;
  background-color: gray;
  border-radius: 50%;
  animation: typing-bounce 1.4s infinite ease-in-out;
}

.typing-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing-bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
